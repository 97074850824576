import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb el peu de major dimensions que el capell. El peu de 10 a 15 cm és cilíndric i més gruixat a la base de color més clar que el capell. Aquest de 10 a 20 cm de diàmetre, al principi és convex i amb el contorn molt enrotllat, però després s’estén i apareix deprimit pel centre i amb un mamelló, conservant una mica els marges un poc enrotllats. És de color rosa carn o groc ocraci molt clar. Davall el capell presenta nombroses làmines molt espaiades, decurrents i de color blanquinós. Les espores són blanques en massa, el·lipsoïdals, de 6-7 x 5-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      